@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap);
body {
  margin: 0;
  font-family: 'Nunito', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100%; */
}

#root {
  height : 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}
.main {
  height: 100%;
  width: 100%;
  display: table;
}
.wrapper {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .main {
    height: inherit;
    width: inherit;
    display: block;
  }

  .wrapper {
    display: block;
    height: inherit;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.player {
  width: 100%;
  height: 100%;
}
