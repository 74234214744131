@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100%; */
}

#root {
  height : 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}
.main {
  height: 100%;
  width: 100%;
  display: table;
}
.wrapper {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .main {
    height: inherit;
    width: inherit;
    display: block;
  }

  .wrapper {
    display: block;
    height: inherit;
  }
}